new WOW({ offset: 70 }).init();

function loadSliders() {
	$('.featured-products__row').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 5,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

if (sliderScript) {
	sliderScript.addEventListener('load', function () {
		loadSliders();
	});
}

$(document).ready(function () {

	if ($('.homepage-marker').length > 0 && window.matchMedia('(min-width:768px)').matches) {
		$('.header__logo img')
			.addClass("wow bounceInDown")
			.attr({ "data-wow-delay": "0.2s", "data-wow-duration": "1.3s" })
			.css("visibility", "visible");
	} else {
		$('.header__logo img').css("visibility", "visible");
	}

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$(".toplinks__dropdown").each(function () {
		$(".toplinks__dropdown-link", this).each(function (index, value) {
			$(this).attr({ "data-wow-delay": index / 10 + "s", "data-wow-offset": 0 })
				.addClass("wow fadeIn");
		});
	});

	function fixHeights() {
		$('.RotatorTestimonial').sameHeight();
		$('.featured-categories__title').sameHeight();
		$('.featured-categories__subtitle').sameHeight();

		if (window.matchMedia('(min-width:768px)').matches) {
			$(".featured-categories__link").sameHeight();
			$(".featured-categories__description").sameHeight();
		}

	}

	setTimeout(fixHeights, 300);

	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});

});

